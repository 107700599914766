<template>
  <div class="btx-ck-editor">
    <ckeditor
      :editor="editor"
      :disabled="disabled"
      @input="onInput"
      @change="onInput"
      :value="editorData"
      :config="editorConfig"
      @ready="editorReady = true"
    ></ckeditor>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
/* import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize"; */
/* import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle"; */
export default {
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editorReady: true,
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // plugins: [ImageResize /* ImageStyle */],
        toolbar: [
          "heading",
          "|",
          "imageUpload",
          "link",
          "bold",
          "italic",
          "|",
          "outdent",
          "indent",
          "|",
          "bulletedList",
          "numberedList",
          "blockQuote",
          "|",
          "undo",
          "redo",
        ],
        extraPlugins: [this.MyCustomUploadAdapterPlugin],
        image: {
          toolbar: [
            "imageTextAlternative",
            "toggleImageCaption",
            "imageStyle:inline",
            "imageStyle:block",
            "imageStyle:side",
            "linkImage",
          ],
        },
      },
    };
  },
  watch: {
    value() {
      this.init();
    },
  },
  methods: {
    onInput(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
    init() {
      this.editorData = this.lodash.toString(this.value);
    },
    MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    },
  },
  mounted() {
    let _this = this;
    clearInterval(_this.timeInterval);
    _this.timeInterval = setInterval(function () {
      if (_this.editorReady) {
        _this.editorData = _this.lodash.toString(_this.value);
        clearInterval(_this.timeInterval);
      }
    }, 500);
  },
  components: {
    ckeditor: CKEditor.component,
  },
};

// Custom upload adapter class
class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    xhr.open("POST", process.env.VUE_APP_API_URL + "upload-file", true);
    xhr.setRequestHeader("X-CSRF-TOKEN", "{{ csrf_token() }}");
    xhr.responseType = "json";
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      resolve({
        default: response.url, // The URL to the uploaded image.
      });
    });
  }

  // Sends the request to the server.
  _sendRequest(file) {
    const data = new FormData();
    data.append("upload", file);
    this.xhr.send(data);
  }
}
</script>
